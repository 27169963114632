import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PlanService } from '../services/plan/plan.service';

@Injectable()
export class DraftResolver {
  constructor(private planService: PlanService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.planService.getDraft(route.params['id']);
  }
}
